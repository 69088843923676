import About from "../pages/About/About";
import { Layout } from "components/Layout";
import NotFound from "../pages/NotFound/NotFound";
import { createBrowserRouter } from "react-router-dom";
import { RoutePath } from "./paths";
import Main from "../pages/Main/Main";
import Contacts from "../pages/Contacts/Contacts";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import Prohibited from "../pages/Prohibited";
import Event from "../pages/Event";
import Aml from "../pages/Aml";

export default createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Main />,
      },
      {
        path: RoutePath.ABOUT,
        element: <About />,
      },
      {
        path: RoutePath.DEVELOPERS,
      },

      {
        path: RoutePath.CONTACTS,
        element: <Contacts />,
      },
      {
        path: RoutePath.LOGIN,
      },
      { path: RoutePath.TERMS, element: <Terms /> },
      { path: RoutePath.PRIVACY, element: <Privacy /> },
      { path: RoutePath.PROHIBITED, element: <Prohibited /> },
      { path: RoutePath.EVENT, element: <Event /> },
      { path: RoutePath.AML, element: <Aml /> },
    ],
  },
]);
