import { ContentPageData } from "types/types";
import api from "../api/apiContact";
import { useEffect, useState } from "react";
import parse from "html-react-parser";

const Terms = () => {
  const [pageData, setPageData] = useState<ContentPageData>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getAlmPageData();
        const data = res.data;

        setPageData(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData(); 
  }, []);
  return (
    <section className="content-page terms">
      <div className="content-page__container">
        {pageData && (
          <div>
            <h1 className="content-page__title title">{pageData.title}</h1>
            <h3 className="content-page__subtitle subtitle">
              {pageData.subtitle}
            </h3>
            <div className="content-page__content">
              {parse(pageData.content)}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Terms;
